import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-push-to-mobile',
  templateUrl: './push-to-mobile.component.html',
  styleUrls: ['./push-to-mobile.component.scss'],
})
export class PushToMobileComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
